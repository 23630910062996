var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"section-label mt-3 mb-1 px-2"},[_vm._v(" "+_vm._s(_vm.$t('Distrito'))+" ")]),_c('v-select',{staticClass:"mb-1 ml-2 mr-2",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.distritos,"append-to-body":"","calculate-position":_vm.withPopper,"multiple":true,"loading":_vm.loader.distrito,"label":"desc","item-text":"desc","item-value":"id"},on:{"input":_vm.getConcelhosByDistrito,"change":_vm.getConcelhosByDistrito},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var desc = ref.desc;
return [_vm._v(" "+_vm._s(desc)+" ")]}}]),model:{value:(_vm.localization.distrito),callback:function ($$v) {_vm.$set(_vm.localization, "distrito", $$v)},expression:"localization.distrito"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhum distrito'))+" ")])]),_c('h6',{staticClass:"section-label mt-3 mb-1 px-2"},[_vm._v(" "+_vm._s(_vm.$t('Concelho'))+" ")]),_c('v-select',{staticClass:"mb-1 ml-2 mr-2",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.concelhosGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.concelho,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},on:{"input":_vm.getFreguesiasByConcelho,"change":_vm.getFreguesiasByConcelho},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Concelhos de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}]),model:{value:(_vm.localization.concelho),callback:function ($$v) {_vm.$set(_vm.localization, "concelho", $$v)},expression:"localization.concelho"}},[(_vm.localization.distrito)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhum concelho'))+" ")]):_vm._e()]),_c('h6',{staticClass:"section-label mt-3 mb-1 px-2"},[_vm._v(" "+_vm._s(_vm.$t('Freguesia'))+" ")]),_c('v-select',{staticClass:"mb-1 ml-2 mr-2",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.freguesiasGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.freguesia,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Freguesias de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}]),model:{value:(_vm.localization.freguesia),callback:function ($$v) {_vm.$set(_vm.localization, "freguesia", $$v)},expression:"localization.freguesia"}},[(_vm.localization.concelho)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma freguesia'))+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }