<template>
  <div>

    <h6 class="section-label mt-3 mb-1 px-2">
      {{ $t('Distrito') }}
    </h6>
    <v-select
      v-model="localization.distrito"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="distritos"
      append-to-body
      :calculate-position="withPopper"
      :multiple="true"
      :loading="loader.distrito"
      class="mb-1 ml-2 mr-2"
      label="desc"
      item-text="desc"
      item-value="id"
      @input="getConcelhosByDistrito"
      @change="getConcelhosByDistrito"
    >
      <template #option="{ desc }">
        {{ desc }}
      </template>
      <div slot="no-options">
        {{ $t('Nenhum distrito') }}
      </div>
    </v-select>

    <h6 class="section-label mt-3 mb-1 px-2">
      {{ $t('Concelho') }}
    </h6>
    <v-select
      v-model="localization.concelho"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="concelhosGroup"
      append-to-body
      :calculate-position="withPopperGroup"
      :multiple="true"
      :loading="loader.concelho"
      class="mb-1 ml-2 mr-2"
      label="descFull"
      item-text="descFull"
      item-value="id"
      :selectable="option => option.group === null"
      @input="getFreguesiasByConcelho"
      @change="getFreguesiasByConcelho"
    >
      <template #option="{ group, desc }">
        <div
          v-if="group"
          class="group"
        >
          {{ $t('Concelhos de') }} {{ group }}
        </div>
        {{ desc }}
      </template>
      <div
        v-if="localization.distrito"
        slot="no-options"
      >
        {{ $t('Nenhum concelho') }}
      </div>
    </v-select>

    <h6 class="section-label mt-3 mb-1 px-2">
      {{ $t('Freguesia') }}
    </h6>
    <v-select
      v-model="localization.freguesia"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="freguesiasGroup"
      append-to-body
      :calculate-position="withPopperGroup"
      :multiple="true"
      :loading="loader.freguesia"
      class="mb-1 ml-2 mr-2"
      label="descFull"
      item-text="descFull"
      item-value="id"
      :selectable="option => option.group === null"
    >
      <template #option="{ group, desc }">
        <div
          v-if="group"
          class="group"
        >
          {{ $t('Freguesias de') }} {{ group }}
        </div>
        {{ desc }}
      </template>
      <div
        v-if="localization.concelho"
        slot="no-options"
      >
        {{ $t('Nenhuma freguesia') }}
      </div>
    </v-select>

  </div>
</template>

<script>
import store from '@/store'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import localizationModule from '@store-modules/localization/pt'
import { mapGetters } from 'vuex'
import { showMsgRequest, eventsCustomSelects } from '@core-custom/mixins/geral'

export default {
  components: {
    vSelect,
  },
  mixins: [showMsgRequest, eventsCustomSelects],
  data() {
    return {
      loader: {
        distrito: false,
        concelho: false,
        freguesia: false,
      },
      localization: {
        distrito: [],
        concelho: [],
        freguesia: [],
      },
    }
  },
  computed: {
    ...mapGetters('localizationMultiple', ['distritos', 'concelhos', 'freguesias']),
    concelhosGroup: {
      get() {
        const options = []

        try {
          this.concelhos.forEach(o => {
            if (o.distrito) {
              const newOption = {}
              newOption.id = null
              newOption.desc = null
              newOption.descFull = null
              newOption.group = o.distrito
              options.push(newOption)
            }

            if (o.concelhos.length > 0) {
              o.concelhos.forEach(oConcelho => {
                const newSubOption = {}
                newSubOption.id = oConcelho.id
                newSubOption.desc = oConcelho.desc
                newSubOption.descFull = `${oConcelho.desc}, ${o.distrito}`
                newSubOption.group = null
                options.push(newSubOption)
              })
            }
          })
        } catch (err) {
        //
        }

        return options
      },
    },
    freguesiasGroup: {
      get() {
        const options = []

        try {
          this.freguesias.forEach(o => {
            if (o.concelho) {
              const newOption = {}
              newOption.id = null
              newOption.desc = null
              newOption.descFull = null
              newOption.group = `${o.concelho}, ${o.distrito}`
              options.push(newOption)
            }

            if (o.freguesias.length > 0) {
              o.freguesias.forEach(oFreguesia => {
                const newSubOption = {}
                newSubOption.id = oFreguesia.id
                newSubOption.desc = oFreguesia.desc
                newSubOption.descFull = `${oFreguesia.desc}, ${o.concelho}, ${o.distrito}`
                newSubOption.group = null
                options.push(newSubOption)
              })
            }
          })
        } catch (err) {
          //
        }

        return options
      },
    },
    viewData() {
      const txtDefault = '-----'
      let txtDistritos = [txtDefault]
      let txtConcelhos = [txtDefault]
      let txtFreguesias = [txtDefault]

      try {
        if ((this.localization.distrito !== null) && (this.localization.distrito.length > 0)) {
          if ((this.localization !== null) && ('distrito' in this.localization)) {
            txtDistritos = []
            this.localization.distrito.forEach(row => {
              txtDistritos.push(row.desc)
            })
          }
        }

        if ((this.localization.concelho !== null) && (this.localization.concelho.length > 0)) {
          if ((this.localization !== null) && ('concelho' in this.localization)) {
            txtConcelhos = []
            this.localization.concelho.forEach(row => {
              txtConcelhos.push(row.descFull)
            })
          }
        }

        if ((this.localization.freguesia !== null) && (this.localization.freguesia.length > 0)) {
          if ((this.localization !== null) && ('freguesia' in this.localization)) {
            txtFreguesias = []
            this.localization.freguesia.forEach(row => {
              txtFreguesias.push(row.descFull)
            })
          }
        }
      } catch (err) {
        //
      }

      return {
        distritos: txtDistritos,
        concelhos: txtConcelhos,
        freguesias: txtFreguesias,
      }
    },
  },
  watch: {
    localization: {
      handler() {
        this.$emit('updateValueLocalization', this.getDataSaveForm())
      },
      deep: true,
    },
  },
  async created() {
    try {
      this.loader.distrito = true
      await store.dispatch('localizationMultiple/getAllDistritos').then(() => {
        this.loader.distrito = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
        this.loader.distrito = false
      })
    } catch (err) {
      //
    }
  },
  methods: {
    async getConcelhosByDistrito() {
      const self = this

      store.commit('localizationMultiple/setConcelhos', [])
      this.localization.concelho = []

      store.commit('localizationMultiple/setFreguesias', [])
      this.localization.freguesia = []

      try {
        if (this.localization.distrito) {
          self.loader.concelho = true
          await store.dispatch('localizationMultiple/getAllConcelhosByDistrito', {
            distrito: this.localization.distrito,
          }).then(() => {
            self.loader.concelho = false
          }).catch(error => {
            self.loader.concelho = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getFreguesiasByConcelho() {
      const self = this

      store.commit('localizationMultiple/setFreguesias', [])
      this.localization.freguesia = []

      try {
        if (this.localization.distrito && this.localization.concelho) {
          self.loader.freguesia = true
          await store.dispatch('localizationMultiple/getAllFreguesiasByConcelho', {
            distrito: this.localization.distrito,
            concelho: this.localization.concelho,
          }).then(() => {
            self.loader.freguesia = false
          }).catch(error => {
            self.loader.freguesia = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    clearForm() {
      this.localization.distrito = []

      store.commit('localizationMultiple/setConcelhos', [])
      this.localization.concelho = []

      store.commit('localizationMultiple/setFreguesias', [])
      this.localization.freguesia = []
    },
    async getDataSaveForm() {
      const request = await new Promise(resolve => {
        const aLevel1 = []
        const aLevel2 = []
        const aLevel3 = []
        const aLevel4 = []
        const aLevel5 = []

        try {
          if (this.localization.distrito.length > 0) {
            this.localization.distrito.forEach(o => {
              aLevel1.push(o.id)
            })
          }

          if (this.localization.concelho.length > 0) {
            this.localization.concelho.forEach(o => {
              aLevel2.push(o.id)
            })
          }

          if (this.localization.freguesia.length > 0) {
            this.localization.freguesia.forEach(o => {
              aLevel3.push(o.id)
            })
          }
        } catch (err) {
        //
        }

        resolve({
          level1: aLevel1,
          level2: aLevel2,
          level3: aLevel3,
          level4: aLevel4,
          level5: aLevel5,
        })
      })

      return request
    },
    async loadDataForm(payload) {
      return new Promise(resolve => {
        (async () => {
          this.loader.distrito = true
          await store.dispatch('localizationMultiple/getAllDistritos').then(() => {
            this.loader.distrito = false
          }).catch(error => {
            this.showMsgErrorRequest(error)
            this.loader.distrito = false
          })

          if (Array.isArray(payload.level1) && (payload.level1.length > 0)) {
            payload.level1.forEach(val => {
              const newItem = this.distritos.find(o => Number(o.id) === Number(val))
              if (newItem !== undefined) {
                this.localization.distrito.push(newItem)
              }
            })

            await this.getConcelhosByDistrito(this.localization.distrito)
          }

          if (Array.isArray(payload.level2) && (payload.level2.length > 0)) {
            payload.level2.forEach(val => {
              const newItem = this.concelhosGroup.find(o => Number(o.id) === Number(val))
              if (newItem !== undefined) {
                this.localization.concelho.push(newItem)
              }
            })

            await this.getFreguesiasByConcelho(this.localization.concelho)
          }

          if (Array.isArray(payload.level3) && (payload.level3.length > 0)) {
            payload.level3.forEach(val => {
              const newItem = this.freguesiasGroup.find(o => Number(o.id) === Number(val))
              if (newItem !== undefined) {
                this.localization.freguesia.push(newItem)
              }
            })
          }

          await this.$nextTick()

          resolve(true)
        })()
      })
    },
  },
  setup() {
    const LOCALIZATION_MODULE_NAME = 'localizationMultiple'

    if (!store.hasModule(LOCALIZATION_MODULE_NAME)) {
      store.registerModule(LOCALIZATION_MODULE_NAME, localizationModule)

      onUnmounted(() => {
        if (store.hasModule(LOCALIZATION_MODULE_NAME)) store.unregisterModule(LOCALIZATION_MODULE_NAME)
      })
    }

    return {
    }
  },
}
</script>
